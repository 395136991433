import { useEffect, useState } from "react";
import { PortableText } from "@portabletext/react";
import sanityClient from "../../client.js";

const Bio = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "bio"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <section className="bio__wrapper">
        <div className="bio__large-text">
          <PortableText value={pageData.information} />
          <div className="bio__sub-text-wrapper">
          <div className="bio__sub-text alt-font">
            <p>&#9733; &#9733; &#9733; &#9733; &#9733;</p>
            <PortableText value={pageData.subOne} />
          </div>
          <div className="bio__sub-text alt-font">
          <p>&#9733; &#9733; &#9733; &#9733; &#9733;</p>

            <PortableText value={pageData.subTwo} />
          </div>
        </div>
        <div className="bio__disclaimer alt-font">[All opinions of fictional origin]</div>
        </div>

      </section>
    );
  }
};

export default Bio;
