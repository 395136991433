import { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import Header from "./components/global/Header";
import Home from "./views/Home";

import "./styles/global-styles.scss";

function App() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  useEffect(() => {
    const checkWidth = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkWidth();
    window.addEventListener("resize", checkWidth, false);
  });

  const [flightMode, setFlightMode] = useState(false);

  const handleFlightMode = () => {

    const doc = document.documentElement;

    if(flightMode){
      setFlightMode(false);
      doc.style.setProperty("--dom-bkg", `#FFFFFF`);
      doc.style.setProperty("--hover-color", `#0000FF`);
      doc.style.setProperty("--line-color", `#000000`);
      doc.style.setProperty("--text-color", `#000000`);
      doc.style.setProperty("--line-color-blend", `#FFFFFF`);
      doc.style.setProperty("--text-shadow", `none`);
      doc.style.setProperty("--mix-blend", `difference`);
      doc.style.setProperty("--jet-background", `linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 0) 100%
      )`);
      doc.style.setProperty("--contact-bkg", `#FFFFFF`);
      doc.style.setProperty("--archive-button", `#EEEEEE`);
      doc.style.setProperty("--line-width", `0.5px`);

    } else {
      setFlightMode(true);
      doc.style.setProperty("--dom-bkg", `#FFFFFF00`);
      doc.style.setProperty("--hover-color", `#dc4242`);
      doc.style.setProperty("--line-color", `#dc4242`);
      doc.style.setProperty("--line-color-blend", `#dc4242`);
      doc.style.setProperty("--text-color", `#FFFFFF`);
      doc.style.setProperty("--text-shadow", `0px 0px 6px #FFF`);
      doc.style.setProperty("--mix-blend", `none`);
      doc.style.setProperty("--jet-background", `transparent`);
      doc.style.setProperty("--contact-bkg", `#dc4242`);
      doc.style.setProperty("--archive-button", `#EEEEEE30`);
      doc.style.setProperty("--line-width", `1px`);

    }

  }


  return (
    <>
    {/* <Background /> */}
      <Header {...{ isMobile, flightMode, setFlightMode, handleFlightMode }}/>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route element={<Home key={"home"} {...{ isMobile, flightMode, setFlightMode, handleFlightMode }} />} path="/" />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
