import { motion } from "framer-motion";
import LogoRush from "../../assets/icons/LogoRush";

const Splash = (props) => {
  const { isMobile, flightMode } = props;
  return (
    <motion.section
      key={"splash-wrapper"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="splash__wrapper"
      id="splashWrapper"
    >
      <div className="splash__logo-wrapper">
        <LogoRush
          stroke={flightMode ? "#dc4242" : "none"}
          fill={flightMode ? "#dc4242" : "#000000"}
          classes={"splash_logo"}
          rushFill={flightMode ? "#dc4242" : "none"}
        />
      </div>
    </motion.section>
  );
};

export default Splash;
