const Logo = (props) => {
  const { fill, classes } = props;
  return (
    <svg
      //   width="122"
      //   height="600"
      className={classes}
      viewBox="0 0 122 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.1006 26.3097H91.6233V2.09619H96.8136L100.572 19.4919V2.09619H104.049V26.3097H99.0892L95.1006 8.35163V26.3097Z"
        fill={fill}
      />
      <path d="M89.6397 26.3097H85.8044V2.09619H89.6397V26.3097Z" fill={fill} />
      <path
        d="M74.5788 26.3097H70.6157L75.4992 2.09619H80.3061L85.1129 26.3097H80.9964L80.1271 21.4248H75.4225L74.5788 26.3097ZM77.7492 7.61363L76.0873 17.0319H79.4623L77.7492 7.61363Z"
        fill={fill}
      />
      <path
        d="M61.072 18.1565L63.6288 2.09619H69.9697V26.3097H66.2112V7.15677L62.7084 26.3097H59.0777L55.5748 6.87563V26.3097H51.9697V2.09619H58.5407L61.072 18.1565Z"
        fill={fill}
      />
      <path
        d="M40.4687 26.3097H34.6392V2.09619H40.7244C45.1732 2.09619 47.3977 7.12163 47.3977 14.2205C47.3977 20.2651 45.4545 26.3097 40.4687 26.3097ZM38.4744 6.80534V21.6005H40.1107C42.284 21.6005 43.3835 19.5622 43.3835 13.9745C43.3835 8.42192 42.1306 6.80534 40.034 6.80534H38.4744Z"
        fill={fill}
      />
      <path d="M32.6558 26.3097H28.8206V2.09619H32.6558V26.3097Z" fill={fill} />
      <path
        d="M20.6719 26.8722C16.8367 26.8722 13.9475 22.6551 13.9475 14.2208C13.9475 5.75133 16.8878 1.53418 20.7231 1.53418C24.5583 1.53418 27.4986 5.75133 27.4986 14.2208C27.4986 22.6551 24.5072 26.8722 20.6719 26.8722ZM20.6719 22.1279C22.3339 22.1279 23.4844 19.7382 23.4844 14.2208C23.4844 8.6682 22.385 6.27847 20.7231 6.27847C19.0611 6.27847 17.9617 8.6682 17.9617 14.2208C17.9617 19.7382 19.01 22.1279 20.6719 22.1279Z"
        fill={fill}
      />
      <path
        d="M7.21022 20.8274L10.5085 2.09619H14.4716L9.48579 26.3097H4.65341L0 2.09619H4.06534L7.21022 20.8274Z"
        fill={fill}
      />
      <path
        d="M115.951 30L114.98 27.9684C117.025 25.9706 118.125 21.4334 118.125 15.2709C118.125 8.60044 117.153 4.46952 114.954 2.0316L115.926 0C119.531 1.89616 121.934 7.61851 121.934 15.1354C121.934 22.6862 119.556 28.0361 115.951 30Z"
        fill={fill}
      />
      <path
        d="M113.207 27.9684L112.236 30C108.631 28.0361 106.253 22.6862 106.253 15.1354C106.253 7.61851 108.656 1.89616 112.261 0L113.233 2.0316C111.034 4.46952 110.063 8.60044 110.063 15.2709C110.063 21.4334 111.162 25.9706 113.207 27.9684Z"
        fill={fill}
      />
    </svg>
  );
};

export default Logo;
