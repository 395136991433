import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../hooks/useProgressiveImage.js";
import { getFile } from "@sanity/asset-utils";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Mobile = (props) => {
  const { media, videoPoster } = props;

  const smallUrl =
    media && media._type === "image" ? `${urlFor(media)}?q=10&w=5&h=5` : null;
  const largeUrl =
    media && media._type === "image" ? `${urlFor(media)}?w1080&h=700` : null;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  if (media._type === "video") {
    const vidSrc = media.asset
      ? getFile(media.asset, sanityClient.config())
      : null;

    return (
      <div className="media__media-wrapper">
        <div className="media__mobile-bar">
          <div className="media__mobile-bar-top">
            <div className="media__mobile-bar-top-item ">6 : 6 6</div>
            <div className="media__mobile-bar-top-item middle" />
            <div className="media__mobile-bar-top-item">
              {" "}
              <div className="media__mobile-battery-wrapper">
                <div className="media__mobile-battery-charge" />
              </div>
            </div>
          </div>
          <div className="media__mobile-bar-bottom">
            https://VoidMain.studio
          </div>
        </div>
        <video
          src={vidSrc.asset.url}
          autoPlay
          muted
          playsInline
          loop
          className="media__mobile-video-wrapper"
          poster={videoPoster}
        />
      </div>
    );
  } else if (media._type === "image") {
    return (
      <div className="media__media-wrapper">
        <div className="media__mobile-bar">
          <div className="media__mobile-bar-top">
            <div className="media__mobile-bar-top-item ">6 : 6 6</div>
            <div className="media__mobile-bar-top-item middle" />
            <div className="media__mobile-bar-top-item">
              <div className="media__mobile-battery-wrapper">
                <div className="media__mobile-battery-charge" />
              </div>
            </div>
          </div>
          <div className="media__mobile-bar-bottom">
            https://VoidMain.studio
          </div>
        </div>
        <img
          src={src}
          alt="VOID MAIN"
          className="media__mobile-image-wrapper"
          style={{
            filter: blur ? "blur(20px)" : "none",
            transition: blur ? "none" : "filter 0.3s ease-out",
          }}
        />
      </div>
    );
  } else {
    return;
  }
};

export default Mobile;
