import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import Desktop from './Desktop';
import Mobile from './Mobile';
import Regular from './Regular';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const MediaWrapper = (props) => {

    const { mediaArray, selected, archiveImage } = props;

    const videoPoster = `${urlFor(archiveImage)}?w=300&h=300&blur=20`

  return (
    <section className={`media__outer-wrapper ${selected?'selected':''}`}>
        <div className='media__inner-wrapper'>
          {mediaArray && mediaArray.map((media, index)=>{
            if(media.layoutOptions === 'desktop'){
              return <Desktop media={media} key={index} videoPoster={videoPoster}/>
            } else if(media.layoutOptions === 'mobile'){
              return <Mobile media={media} key={index} videoPoster={videoPoster}/>
            } else if(media.layoutOptions === 'regular'){
              return <Regular media={media} key={index} videoPoster={videoPoster}/>
            } else {
              return () => {}
            }
          })}
          <div className='media__spacer'></div>
        </div>
    </section>
  )
}

export default MediaWrapper