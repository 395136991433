import React from "react";
import Sidebar from "./Sidebar";
import Center from "./Center";

const JetBackground = (props) => {
  const { flightMode, handleFlightMode } = props
  return (
    <div className="jet__container">
        <Sidebar position={'left'} {...{flightMode}}/>
        <Sidebar position={'right'} {...{flightMode}}/>
        <Center {...{handleFlightMode}}/>
    </div>
  );
};

export default JetBackground;
