export default function scrollToElement(element) {
    if (!element) return;
  
    const targetTop = element.getBoundingClientRect().top + window.pageYOffset - 70;
  
    if ('scrollBehavior' in document.documentElement.style) {
      window.scrollTo({ top: targetTop, behavior: 'smooth' });
    } else {
      const scrollOptions = {
        behavior: 'smooth',
        block: 'start',
      };
  
      // Polyfill for smooth scrolling
      if (!window.smoothScrollPolyfill) {
        window.smoothScrollPolyfill = function (target, options) {
          const scrollContainer = target.closest('.scrollable') || window;
          const targetY = target.getBoundingClientRect().top + scrollContainer.scrollTop;
          scrollContainer.scrollTo({
            top: targetY - 70,
            behavior: options.behavior || 'auto',
          });
        };
      }
      window.smoothScrollPolyfill(element, scrollOptions);
    }
  }