import { useState, useRef } from "react";
import sanityClient from "../../client.js";
import imageUrlBuilder from "@sanity/image-url";
import useProgressiveImg from "../../hooks/useProgressiveImage.js";

import scrollToElement from "../../hooks/scrollToElement";
import MediaWrapper from "../media/MediaWrapper";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ArchiveProject = (props) => {
  const { project } = props;
  const wrapperRef = useRef();
  const [projectActive, setProjectActive] = useState(false);
  const [mediaVisible, setMediaVisible] = useState(false);

  const toggleActive = () => {
    console.log(project);
    if (!projectActive) {
      setProjectActive(true);
      if (wrapperRef.current) {
        scrollToElement(wrapperRef.current, "smooth");
      }
      setTimeout(() => {
        setMediaVisible(true);
      }, 400);
    } else {
      setMediaVisible(false);
      setTimeout(() => {
        setProjectActive(false);
      }, 400);
    }
  };

  const smallUrl = project.archiveImage
    ? `${urlFor(project.archiveImage)}?q=5&w=5&h=5`
    : null;
  const largeUrl = project.archiveImage
    ? `${urlFor(project.archiveImage)}?w150&h=150`
    : null;
  const [src, { blur }] = useProgressiveImg(smallUrl, largeUrl);

  return (
    <section
      className={`archive__project-wrapper ${projectActive ? "active" : ""}`}
      ref={wrapperRef}
    >
      <div className="archive__title-wrapper">
        <button className="archive__text" onClick={toggleActive}>
          <span className="">{project.tagLine}</span>
          <span className="archive-small-caps"> {project.client}</span>
        </button>
        <div className="archive__project-details alt-font">
        {project.collaborator ? (
            <div className="archive__project-detail credit">
              C/O: {project.collaborator}
            </div>
          ) : (
            ""
          )}
          <button
            className="archive__project-detail"
            onClick={toggleActive}
          >
            See {!projectActive ? "More" : "Less"}
          </button>
          {project.launchLink ? (
            <div className="archive__project-detail">
              <a href={project.launchLink} target="_blank" rel="noreferrer">
                Launch Project
              </a>
            </div>
          ) : (
            ""
          )}
        </div>

        {project.archiveImage ? (
          <button
            className={`archive__image-wrapper ${
              projectActive ? "disabled" : ""
            }`}
            onClick={toggleActive}
          >
            <img
              src={src}
              alt={project.title}
              className="archive__image"
              style={{
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.3s ease-out",
              }}
            />
          </button>
        ) : (
          ""
        )}
      </div>
      <div className={`archive__media-wrapper ${mediaVisible ? "active" : ""}`}>
        {project.media && projectActive ? (
          <MediaWrapper mediaArray={project.media} selected={false} archiveImage={project.archiveImage}/>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default ArchiveProject;
