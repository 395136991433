import { useEffect, useState } from "react";
import sanityClient from "../../../client.js";
import AltMarker from "./AltMarker";

const Center = (props) => {
  const {handleFlightMode} = props;

  const [pageData, setPageData] = useState(null);
  const [mousePercent, setMousePercent] = useState(0);
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [year, setYear] = useState(2000);

  useEffect(() => {
    const updateMouse = (e) => {
      const topInPercent = (e.clientY / window.innerHeight) * 100;
      setMousePercent(topInPercent);

      const mouseX = e.clientX - ((window.innerWidth / 2) - 55);
      const mouseY = e.clientY - window.innerHeight / 2;
      setMouse({ x: mouseX.toFixed(0), y: mouseY.toFixed(0) });
    };

    const setAltitude = (e) => {
      const angle = (e.clientX / window.innerWidth - 0.5) * 25;
      setRotation(angle);
    };

    window.addEventListener("mousemove", updateMouse);
    window.addEventListener("mousemove", setAltitude);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "contact"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
    setYear(() => new Date().getFullYear());
  }, []);

  return (
    <div className="jet__center-container">
      <div className="jet__center-crop-mark left">
        <div className="jet__crop-button left top">
          {pageData && pageData.secretLink ? (
            <a href={pageData.secretLink} target="_blank" rel="noreferrer">
              V M &#9786; {year}
            </a>
          ) : (
            ""
          )}
        </div>
        <div className="jet__crop-button left bottom">R L L : {mouse.x}</div>

        <div className="jet__center-crop-alt-mark" />
        <div className="jet__center-crop-alt-mark" />
        <div className="jet__center-crop-alt-mark" />
        <div className="jet__center-crop-alt-mark" />
      </div>
      <div className="jet__center-crop-mark right">
        <div className="jet__crop-button right top" ><button onClick={handleFlightMode}>M O D E</button></div>
        <div className="jet__crop-button right bottom">P T C : {-mouse.y}</div>

        <div className="jet__center-crop-alt-mark right" />
        <div className="jet__center-crop-alt-mark right" />
        <div className="jet__center-crop-alt-mark right" />
        <div className="jet__center-crop-alt-mark right" />
      </div>

      <div className="jet__center-wrapper circle">
        <div
          className="jet__center-circle"
          style={{ top: `calc(${mousePercent}% - 15px)` }}
        >
          <span className="jet__center-circle-crop" />
          <span className="jet__center-circle-dot" />
        </div>
      </div>

      <div className="jet__center-wrapper">
        <div
          className="jet__center-wrapper-altitude"
          style={{
            transform: `rotate(${rotation}deg)`,
            top: `calc(${mousePercent - 75}% - 2.5vh - 2.5px)`,
          }}
        >
          <AltMarker direction={"plus"} />
          <AltMarker direction={"plus"} />
          <AltMarker direction={"plus"} />
          <div className="jet__alt-marker-center">
            <div className="jet__alt-marker-center-marker left" />
            <div className="jet__alt-marker-center-marker right" />
          </div>
          <AltMarker direction={"minus"} />
          <AltMarker direction={"minus"} />
          <AltMarker direction={"minus"} />
        </div>
      </div>
    </div>
  );
};

export default Center;
