import Cherub from "../../assets/icons/Cherub";
import Logo from "../../assets/icons/Logo";
import scrollToElement from "../../hooks/scrollToElement";

const Header = (props) => {
  const { isMobile, flightMode, setFlightMode, handleFlightMode } = props;

  const goToInfo = () => {
    const elm = document.getElementById("contactWrapper");
    scrollToElement(elm, "smooth");
  };

  const goToTop = () => {
    const elm = document.getElementById("splashWrapper");
    scrollToElement(elm, "smooth");
  };
  return (
    <>
      <button
        className="header__piss-wrapper header-blur piss"
        onClick={handleFlightMode}
      >
        {/* <img src="./assets/icons/FUCK-YOU.svg" alt="VOID MAIN() FUCK YOU" /> */}
        <Cherub
            fill={!flightMode ? "#000000" : "#FFFFFF"}
            classes={"header_type-logo"}
          />
      </button>
      <div className="header__type-container">
        <div
          className="header__type-wrapper header-blur alt-font"
          onClick={goToTop}
        >
          <Logo
            fill={!flightMode ? "#000000" : "#FFFFFF"}
            classes={"header_type-logo"}
          />
        </div>
      </div>
      <button className="header__info header-blur alt-font" onClick={goToInfo}>
        Info
      </button>
    </>
  );
};

export default Header;
