import MediaWrapper from "../media/MediaWrapper";

const SelectedProject = (props) => {
  const { project } = props;

  return (
    <div className="selected__project-wrapper">
      
        <div className="selected__project-title">
        <div className="selected__text">
          <span className="large-text">{project.tagLine}</span>
          <span className="small-caps"> {project.client}</span>
          {project.collaborator ? (
            <span className="selected__collaborator alt-font">
              {"  "}C/O {project.collaborator}
            </span>
          ) : (
            ""
          )}
        </div>
        {project.launchLink ? (
        <div className="selected__buttons">
          <a
            href={project.launchLink}
            target="_blank"
            rel="noreferrer"
            className="selected__launch-button alt-font"
          >
            Launch Project
          </a>
        </div>
      ) : (
        ""
      )}
      </div>

      <MediaWrapper mediaArray={project.media} selected={true} archiveImage={project.archiveImage}/>
    </div>
  );
};

export default SelectedProject;
