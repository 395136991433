const Fuel = (props) => {
    const { fill, classes } = props;


    return (
      <svg
        className={classes}
        viewBox="0 0 357.83 357.83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
	<path d="M330.469,114.665h-3.771V63.784l-23.986-24.095l-2.455,18.996l8.609,8.716v47.265h-3.773v52h4.676v89.646
		c0,10.477-8.523,19-19,19s-19-8.523-19-19v-90.851h-27.99v-8.267h14.164L284.768,0H27.361l26.826,157.195h14.164v149.072H39.707
		v51.562h232.715v-51.562h-28.646V181.462h11.99v74.851c0,19.299,15.701,35,35,35s35-15.701,35-35v-89.647h4.703V114.665
		L330.469,114.665z M224.188,117.195H87.939L74.766,40h162.596L224.188,117.195z" fill={fill}/>
      </svg>
    );
  };
  
  export default Fuel;
  