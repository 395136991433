import Cherub from "../../assets/icons/Cherub";

const Background = (props) => {
  const { flightMode } = props;

  return (
    <div className={`background__wrapper ${flightMode ? "disabled" : ""}`}>
      {/* <img
        src="./assets/icons/FUCK-YOU_GREY.svg"
        alt="VOID MAIN() FUCK YOU"
        className="background__image"
      /> */}
      <div className="background__logo-wrapper">
        <Cherub fill={"#EEEEEE"} classes={"background__image"} />
      </div>
    </div>
  );
};

export default Background;
