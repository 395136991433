import { useRef, useState } from "react";
import { motion } from "framer-motion";
import Splash from "../components/home/Splash";
import LatestProject from "../components/home/LatestProject";
import Bio from "../components/home/Bio";
import Clients from "../components/home/Clients";
import SelectedProjects from "../components/home/SelectedProjects";
import Press from "../components/home/Press";
import Archive from "../components/home/Archive";
import Contact from "../components/home/Contact";
import Background from "../components/global/Background";
import Scrollbar from "../components/global/Scrollbar";
import Sky from "../components/backgrounds/Sky";


const Home = (props) => {

  const { isMobile, flightMode, setFlightMode, handleFlightMode } = props;
  const domWrapper = useRef();

  return (
    <>
      <Sky {...{flightMode}} />
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        ref={domWrapper}
      >
        <Scrollbar domWrapper={domWrapper} {...{flightMode}} />
        <section>

          <Background {...{flightMode}} />
          <Splash  {...{flightMode}}/>
          <LatestProject />
          <Bio />
        </section>
        <section>
          <Clients {...{flightMode, handleFlightMode}}/>
        </section>
        <section>
        <Background {...{flightMode}} />
        <SelectedProjects />
        </section>
        <section>
        <Press {...{flightMode, handleFlightMode}}/>
        </section>
        <section>
        <Background {...{flightMode}} />
        <Archive />
        </section>
        <Contact />
      </motion.main>
    </>
  );
};

export default Home;
