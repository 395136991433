import { useEffect, useState } from "react";
import sanityClient from "../../client.js";

import SelectedProject from "./SelectedProject.js";

const SelectedProjects = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "selectedProjects"][0]{
        projects[]->{...}
      }`)
      .then((data) => {
        setPageData(data.projects);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <section className="selected__wrapper">
        <div className="section__title padding">
          <div className="header-blur alt-font" >Recent Work:</div>
        </div>
        {pageData.map((project, index)=>{
          return <SelectedProject project={project} key={index}/>
        })}
      </section>
    );
  }
};

export default SelectedProjects;
