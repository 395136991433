import { useRef, useEffect, useState } from 'react'
import Fuel from '../../assets/icons/Fuel';


const Scrollbar = (props) => {

    const { domWrapper, flightMode } = props;

    const scrollHeight = useRef(0);
    const [ scrollPercentage, setScrollPercentage ] = useState(100);

  useEffect(() => {
    const handleResize = () => {
        scrollHeight.current = document.documentElement.scrollHeight - window.innerHeight;
    }
    handleResize()
    window.addEventListener('resize', handleResize);
  }, [document.documentElement.scrollHeight]);

  useEffect(() => {
    const handleScroll = () => {
        const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop || 0;
        setScrollPercentage(100 - ((scrollPosition / scrollHeight.current) * 100));
    }
    handleScroll()
    window.addEventListener('scroll', handleScroll);
  }, [domWrapper]);


  return (
    <aside className='scrollbar__wrapper'>
        <div className='scrollbar__inner-wrapper'>
            <div className='scrollbar__mark top' />
            <div className='scrollbar__mark seventy-five' />
            <div className='scrollbar__mark fifty' />
            <div className='scrollbar__mark twenty-five' />
            <div className='scrollbar__mark bottom' />
            <div className={`scrollbar__level-wrapper ${scrollPercentage <= 25?'low':''}`}
            style={{height: `calc(${scrollPercentage}% - 8px)`}} />
        </div>
        <div className='scrollbar__icon-wrapper'>
            {/* <img src="./assets/icons/FUEL.svg" alt="FUEL" className='scrollbar__icon'/> */}
            <Fuel fill={flightMode?'#dc4242':'#000000'} classes={'scrollbar__icon'} />
            <div className='scrollbar__text-wrapper' style={{color: flightMode?'#dc4242':'#000'}}>
            {scrollPercentage.toFixed(0)}{" "}%
            </div>
        </div>
    </aside>
  )
}

export default Scrollbar