import { useEffect, useState } from "react";
import sanityClient from "../../client.js";
import MediaWrapper from "../media/MediaWrapper.js";

const LatestProject = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "latestProject"][0]{
        project->{...}
      }`
      )
      .then((data) => {
        setPageData(data.project);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <section className="latest__wrapper">
        <div className="latest__title">
          <div
            className={`latest__text-wrapper ${
              pageData.launchLink || pageData.collaborator ? "small" : ""
            }`}
          >
            <span className="small-caps latest__latest">LATEST:</span>
            <span className="large-text"> {pageData.tagLine}</span>
            <span className="small-caps"> {pageData.client}</span>
            {pageData.collaborator ? (
                <div className="latest__launch-button collaborator alt-font">
                  C/O {pageData.collaborator}
                </div>
              ) : (
                ""
              )}
              {pageData.launchLink ? (
                <a
                  href={pageData.launchLink}
                  target="_blank"
                  rel="noreferrer"
                  className="latest__launch-button launch alt-font"
                >
                  Launch Project
                </a>
              ) : (
                ""
              )}
          </div>
          {/* {pageData.launchLink || pageData.collaborator ? (
            <div className="latest__buttons-wrapper">
              {pageData.collaborator ? (
                <div className="latest__launch-button collaborator alt-font">
                  C/O {pageData.collaborator}
                </div>
              ) : (
                ""
              )}
              {pageData.launchLink ? (
                <a
                  href={pageData.launchLink}
                  target="_blank"
                  rel="noreferrer"
                  className="latest__launch-button alt-font"
                >
                  Launch Project
                </a>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )} */}

        </div>
        <MediaWrapper mediaArray={pageData.media} archiveImage={pageData.archiveImage}/>
        {pageData.launchLink ? (
                <a
                  href={pageData.launchLink}
                  target="_blank"
                  rel="noreferrer"
                  className="latest__launch-mobile alt-font"
                >
                  Launch Project
                </a>
              ) : (
                ""
              )}
      </section>
    );
  }
};

export default LatestProject;
