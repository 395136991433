import { useEffect, useState } from "react";
import { PortableText } from "@portabletext/react";
import sanityClient from "../../client.js";

const Bio = () => {
  const [pageData, setPageData] = useState(null);
  const [year, setYear] = useState(2000);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "contact"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
    setYear(() => new Date().getFullYear());
  }, []);

  if (pageData) {
    return (
      <section className="contact__wrapper">
        <div className="contact__content-wrapper">
          <div className="section__title padding">
            <div className="header-blur alt-font">Get in touch:</div>
          </div>
          <div className="contact__large-text">
            <a href={`mailto:${pageData.emailAddress}`}>
              {pageData.emailAddressText}
            </a>
          </div>
          <div className="contact__sub-text-wrapper">
            <div className="contact__sub-text alt-font contact__border">
              {pageData.socials.map((social, index) => {
                return (
                  <a
                    href={social.entryLink}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    className="contact__social"
                  >
                    {social.entryName}
                    {index === pageData.socials.length - 1 ? "." : ","}
                  </a>
                );
              })}
              <div className="contact__address">
                <PortableText value={pageData.address} />
              </div>
            </div>
            <div className="contact__sub-text alt-font contact__border">
              Services:
              <ul className="contact__address contact__list">
                <div>Including but not limited to:</div>
                {pageData.services.map((service, index) => {
                  return (
                    <li className="contact__list-item" key={index}>
                      {service.entryName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="contact__copy" id="contactWrapper">
          ALL CONTENT &copy; VOIDMAIN() {year}
        </div>
      </section>
    );
  }
};

export default Bio;
