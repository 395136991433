import { useEffect, useState, useRef } from "react";
import sanityClient from "../../../client.js";

const SpedoBackground = (props) => {
  const { flightMode, handleFlightMode } = props;

  const [pageData, setPageData] = useState(null);
  const [year, setYear] = useState(2000);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "contact"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
    setYear(() => new Date().getFullYear());
  }, []);

  const fuelValue = useRef(130);
  const tempValue = useRef(90);

  const [mphValue, setMphValue] = useState(-30);
  const [mph, setMph] = useState(0);

  const [rpmValue, setRpmValue] = useState(-30);
  const [rpm, setRpm] = useState(0);

  useEffect(() => {
    let interval;
    if (mph !== 0) {
      interval = setInterval(() => {
        setMphValue((prevValue) => {
          const newValue = prevValue + mph;
          return Math.min(210, Math.max(-30, newValue));
        });

        if (mph > 0 && fuelValue.current > 50) {
          fuelValue.current = fuelValue.current - 0.1;
        } else {
          handleMouseUp();
        }
      }, 10);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [mph]);

  useEffect(() => {
    let interval;
    if (rpm !== 0) {
      interval = setInterval(() => {
        setRpmValue((prevValue) => {
          const newValue = prevValue + rpm;
          if (newValue > 205) {
            return 180;
          }
          return Math.min(210, Math.max(-30, newValue));
        });

        if (rpmValue > 179) {
          if (tempValue.current < 130) {
            tempValue.current = tempValue.current + 0.1;
          }
        } else {
          if (tempValue.current > 90) {
            tempValue.current = tempValue.current - 0.1;
          }
        }
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [rpm, rpmValue]);

  const handleMouseDown = () => {
    setMph(0.5);
    setRpm(1);
  };

  const handleMouseUp = () => {
    setMph(-0.5);
    setRpm(-1);
  };

  const reset = () => {
    setMph(0);
    setRpm(0);
    setMphValue(-30);
    setRpmValue(-30);
    fuelValue.current = 130;
    tempValue.current = 90;
  };

  return (
    <section className="spedo__container">
      <div className="spedo__border-box" />
      <div className="spedo__wrapper">
        <div className="spedo__dials-wrapper">
          <button
            className={`spedo__button-wrapper center refuel ${
              fuelValue.current < 51 ? "active" : ""
            }`}
            onClick={reset}
          >
            <div>R E F U E L</div>
          </button>
          {pageData && pageData.secretLinkTwo ? (
            <a
              href={pageData.secretLinkTwo}
              target="_blank"
              rel="noreferrer"
              className="spedo__secret-link"
            >
              V M &#9786; {year}
            </a>
          ) : (
            ""
          )}
          <button onClick={handleFlightMode}
            className="spedo__secret-link mode"
          >
            M O D E
          </button>
          <button
            className={`spedo__button-wrapper center refuel ${
              fuelValue.current < 51 ? "active" : ""
            }`}
            onClick={reset}
          >
            <div>R E F U E L</div>
          </button>
          <div className="spedo__dial-small fuel">
            <div
              className="spedo__small-hand"
              style={{ transform: `rotate(${fuelValue.current - 90}deg)` }}
            />
            <div className="spedo__small-marker-wrapper">
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(50deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(70deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(90deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(110deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(130deg)` }}
              />
            </div>
          </div>
          <div className="spedo__dial-large mph">
            <div
              className="spedo__large-hand"
              style={{ transform: `rotate(${mphValue}deg)` }}
            />

            <div className="spedo__large-marker-wrapper">
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(-30deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(-20deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(-10deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(0deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(10deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(20deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(30deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(40deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(50deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(60deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(70deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(80deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(90deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(100deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(110deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(120deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(130deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(140deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(150deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(160deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(170deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(180deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(190deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(200deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(210deg)` }}
              />
            </div>
          </div>
          <div className="spedo__dial-large rpm">
            <div
              className="spedo__large-hand"
              style={{ transform: `rotate(${rpmValue}deg)` }}
            />
            <div className="spedo__large-marker-wrapper">
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(-30deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(-20deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(-10deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(0deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(10deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(20deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(30deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(40deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(50deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(60deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(70deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(80deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(90deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(100deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(110deg)` }}
              />
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(120deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(130deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(140deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(150deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(160deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(170deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(180deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(190deg)` }}
              />{" "}
              <div
                className="spedo__large-marker small"
                style={{ transform: `rotate(200deg)` }}
              />
              <div
                className="spedo__large-marker large"
                style={{ transform: `rotate(210deg)` }}
              />
            </div>
          </div>
          <div className="spedo__dial-small temp">
            <div
              className="spedo__small-hand"
              style={{ transform: `rotate(${tempValue.current - 90}deg)` }}
            />
            <div className="spedo__small-marker-wrapper">
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(50deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(70deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(90deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(110deg)` }}
              />
              <div
                className="spedo__small-marker"
                style={{ transform: `rotate(130deg)` }}
              />
            </div>
          </div>
        </div>
        <div className="spedo__buttons-wrapper">
          <div className="spedo__button-wrapper small">F U E L</div>
          <div className="spedo__button-wrapper large">M P H</div>
          <button
            className="spedo__button-wrapper center"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            <div>G O</div>
          </button>
          <div className="spedo__button-wrapper large">R P M</div>
          <div className="spedo__button-wrapper small">T E M P</div>
          {/* <button className={`spedo__button-wrapper center refuel ${fuelValue.current < 51?'active':''}`} onClick={reset}>
        <div>R E F U E L</div>
        </button> */}
        </div>
      </div>
    </section>
  );
};

export default SpedoBackground;
