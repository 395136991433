import { useEffect, useState } from "react";
import { PortableText } from "@portabletext/react";
import sanityClient from "../../client.js";
import JetBackground from "../backgrounds/jet/JetBackground.js";

const Clients = (props) => {
  const { flightMode, handleFlightMode } = props;
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "clients"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  return (
    <section className="clients__wrapper">
      <div className="section__title padding">
        <div className="header-blur alt-font">Selected Clients:</div>
      </div>
      {pageData ? (
        <div className="clients__text">
          <PortableText value={pageData.information} />
          <h3 className="alt-font">...and loads, loads more.</h3>
        </div>
      ) : (
        ""
      )}
      <JetBackground {...{ flightMode, handleFlightMode }}/>
    </section>
  );
};

export default Clients;
