import React from 'react'

const Sky = (props) => {

    const { flightMode } = props;
  return (
    <div className={`sky-background ${flightMode?'active':''}`} />
  )
}

export default Sky