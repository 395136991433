const LogoRush = (props) => {
  const { rushFill, fill, stroke, classes } = props;
  return (
    <svg
      className={classes}
      viewBox="0 0 1080 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* VOID MAIN */}
  <g>
    <path fill={fill} d="M840.74,639.6h-30.62v-213.24h45.71l33.1,153.19v-153.19h30.62v213.24h-43.68l-35.13-158.15v158.15Z"/>
    <path fill={fill} d="M792.65,639.6h-33.78v-213.24h33.78v213.24Z"/>
    <path fill={fill} d="M660.01,639.6h-34.9l43.01-213.24h42.33l42.33,213.24h-36.25l-7.66-43.02h-41.43l-7.43,43.02ZM687.93,474.95l-14.64,82.94h29.72l-15.09-82.94Z"/>
    <path fill={fill} d="M541.07,567.8l22.52-141.43h55.84v213.24h-33.1v-168.67l-30.85,168.67h-31.97l-30.85-171.15v171.15h-31.75v-213.24h57.87l22.29,141.43Z"/>
    <path fill={fill} d="M359.62,639.6h-51.34v-213.24h53.59c39.18,0,58.77,44.26,58.77,106.77,0,53.23-17.11,106.46-61.02,106.46ZM342.06,467.83v130.29h14.41c19.14,0,28.82-17.95,28.82-67.16s-11.03-63.14-29.5-63.14h-13.73Z"/>
    <path fill={fill} d="M290.82,639.6h-33.77v-213.24h33.77v213.24Z"/>
    <path fill={fill} d="M185.28,644.55c-33.77,0-59.22-37.14-59.22-111.41s25.89-111.72,59.67-111.72,59.67,37.14,59.67,111.72-26.34,111.41-60.12,111.41ZM185.28,602.77c14.64,0,24.77-21.04,24.77-69.63s-9.68-69.94-24.32-69.94-24.32,21.05-24.32,69.94,9.23,69.63,23.87,69.63Z"/>
    <path fill={fill} d="M66.73,591.32l29.05-164.96h34.9l-43.91,213.24h-42.56L3.24,426.36h35.8l27.7,164.96Z"/>
    <path fill={fill} d="M1024.36,672.1l-8.55-17.89c18.01-17.59,27.7-57.55,27.7-111.82,0-58.74-8.56-95.12-27.93-116.59l8.56-17.89c31.75,16.7,52.91,67.09,52.91,133.29s-20.94,113.61-52.69,130.9Z"/>
    <path fill={fill} d="M1000.19,654.21l-8.55,17.89c-31.75-17.3-52.69-64.41-52.69-130.9s21.16-116.59,52.91-133.29l8.56,17.89c-19.37,21.47-27.92,57.85-27.92,116.59,0,54.27,9.68,94.23,27.69,111.82Z"/>
  </g>

{/* Liquid insence */}
  <g>
    <path fill={rushFill} d="M602.41,655.14h6.75v24.63h10.31v6.39h-17.07v-31.03Z"/>
    <path fill={rushFill} d="M621.72,655.14h6.75v31.03h-6.75v-31.03Z"/>
    <path fill={rushFill} d="M649.78,690.26c-1.35,0-2.26-.33-2.72-.99-.47-.66-.7-1.56-.7-2.7v-.08c1.12-.3,2.18-.78,3.15-1.43,1.8-1.22,3.22-3.05,4.28-5.49,1.05-2.45,1.58-5.41,1.58-8.89s-.52-6.49-1.55-8.92c-1.03-2.43-2.45-4.26-4.23-5.49-1.78-1.23-3.83-1.85-6.15-1.85s-4.36.62-6.15,1.85c-1.79,1.23-3.2,3.06-4.24,5.49-1.03,2.43-1.55,5.4-1.55,8.92s.51,6.48,1.53,8.92c1.02,2.43,2.43,4.25,4.21,5.47.93.63,1.93,1.1,2.99,1.4v1.77c0,1.56.36,2.91,1.06,4.05.71,1.14,1.67,2.01,2.88,2.61,1.21.6,2.57.9,4.08.9,1.17,0,2.26-.15,3.27-.45,1-.3,1.85-.72,2.54-1.26v-4.95c-.81.36-1.55.64-2.21.83-.66.2-1.35.29-2.07.29ZM638.57,670.68c0-2.31.19-4.23.58-5.74.39-1.51.95-2.63,1.69-3.35.74-.72,1.6-1.08,2.59-1.08s1.85.36,2.59,1.08c.74.72,1.3,1.84,1.69,3.35.39,1.52.59,3.43.59,5.74s-.2,4.18-.61,5.69c-.41,1.52-.98,2.63-1.73,3.36-.75.72-1.62,1.08-2.61,1.08s-1.84-.36-2.54-1.08c-.71-.72-1.25-1.83-1.65-3.33-.39-1.5-.58-3.41-.58-5.72Z"/>
    <path fill={rushFill} d="M663.24,685.65c-1.62-.83-2.87-2.1-3.76-3.81s-1.33-3.86-1.33-6.44v-20.26h6.75v20.62c0,1.26.18,2.26.54,3,.36.73.85,1.26,1.47,1.58.61.32,1.33.47,2.14.47,1.32,0,2.34-.4,3.06-1.19.72-.8,1.08-2.08,1.08-3.85v-20.62h6.75v20.26c0,3.84-.97,6.72-2.91,8.62-1.93,1.91-4.61,2.86-8.04,2.86-2.22,0-4.14-.41-5.76-1.24Z"/>
    <path fill={rushFill} d="M683.91,655.14h6.75v31.03h-6.75v-31.03Z"/>
    <path fill={rushFill} d="M694.86,655.14h10.72c2.55,0,4.71.65,6.46,1.96s3.07,3.12,3.96,5.45,1.33,5.04,1.33,8.13c0,2.73-.43,5.27-1.29,7.61-.85,2.34-2.19,4.24-4.01,5.69-1.82,1.45-4.12,2.18-6.91,2.18h-10.27v-31.03ZM707.73,679.16c.84-.64,1.47-1.68,1.89-3.11.42-1.43.63-3.32.63-5.69s-.23-4.14-.7-5.49c-.47-1.35-1.13-2.31-1.98-2.86-.85-.55-1.93-.83-3.22-.83h-2.75v18.96h2.88c1.32,0,2.4-.32,3.24-.97Z"/>
    <path fill={rushFill} d="M726.74,655.14h6.75v31.03h-6.75v-31.03Z"/>
    <path fill={rushFill} d="M737.68,655.14h9.14l6.62,22.29v-22.29h6.12v31.03h-8.74l-7.03-23.01v23.01h-6.12v-31.03Z"/>
    <path fill={rushFill} d="M767.96,684.97c-1.76-1.28-3.09-3.11-4.01-5.49s-1.37-5.2-1.37-8.44c0-3.75.55-6.87,1.64-9.37s2.55-4.32,4.37-5.49c1.82-1.17,3.83-1.76,6.06-1.76,2.07,0,3.9.46,5.47,1.37s2.83,2.22,3.76,3.92,1.53,3.72,1.8,6.06l-6.39,1.35c-.12-1.29-.37-2.43-.74-3.42-.38-.99-.89-1.77-1.53-2.34-.64-.57-1.42-.85-2.32-.85-1.11,0-2.04.41-2.79,1.24s-1.3,2-1.67,3.53c-.36,1.53-.54,3.38-.54,5.54s.19,4.03.56,5.52c.37,1.49.93,2.61,1.66,3.36.74.75,1.65,1.13,2.73,1.13,1.5,0,2.66-.71,3.49-2.12s1.28-3.35,1.37-5.81l6.3,1.58c-.21,2.52-.76,4.71-1.64,6.57-.89,1.86-2.15,3.3-3.81,4.32-1.65,1.02-3.68,1.53-6.08,1.53s-4.57-.64-6.33-1.92Z"/>
    <path fill={rushFill} d="M788.3,655.14h18.15v5.99h-11.48v6.39h9.28v5.81h-9.28v6.85h12.16v5.99h-18.82v-31.03Z"/>
    <path fill={rushFill} d="M809.91,655.14h9.14l6.62,22.29v-22.29h6.12v31.03h-8.74l-7.03-23.01v23.01h-6.12v-31.03Z"/>
    <path fill={rushFill} d="M841.16,674.59v1.17c0,1.98.37,3.43,1.1,4.35s1.78,1.37,3.13,1.37c1.02,0,1.85-.29,2.48-.85.63-.57.95-1.35.95-2.34,0-.9-.26-1.64-.77-2.21-.51-.57-1.32-1.14-2.43-1.71l-2.93-1.49c-2.1-1.08-3.87-2.35-5.31-3.8-1.44-1.46-2.16-3.42-2.16-5.88,0-2.91.92-5.1,2.75-6.57,1.83-1.47,4.16-2.21,6.98-2.21,3.36,0,5.85.85,7.45,2.54,1.61,1.7,2.41,3.92,2.41,6.69v.95l-6.08.95v-1.26c0-1.53-.32-2.72-.97-3.58-.64-.85-1.61-1.28-2.9-1.28-.96,0-1.73.25-2.3.74s-.85,1.27-.85,2.32c0,.6.14,1.14.43,1.62s.65.89,1.1,1.24.96.65,1.53.92l3.2,1.48c2.16,1.02,3.95,2.28,5.38,3.78,1.43,1.5,2.14,3.51,2.14,6.04,0,1.83-.41,3.45-1.22,4.86s-1.99,2.51-3.54,3.29-3.38,1.17-5.52,1.17c-2.43,0-4.42-.47-5.97-1.4s-2.68-2.22-3.4-3.87c-.72-1.65-1.08-3.6-1.08-5.86v-.22l6.39-.95Z"/>
    <path fill={rushFill} d="M858.37,655.14h18.15v5.99h-11.48v6.39h9.28v5.81h-9.28v6.85h12.16v5.99h-18.82v-31.03Z"/>
    <path fill={rushFill} d="M894,684.97c-2.6-1.3-4.68-3.19-6.24-5.65-1.56-2.46-2.34-5.36-2.34-8.69s.78-6.23,2.34-8.69,3.64-4.35,6.24-5.65c2.59-1.3,5.38-1.96,8.35-1.96s5.75.66,8.33,1.96c2.58,1.3,4.66,3.19,6.23,5.65s2.37,5.36,2.37,8.69-.79,6.23-2.37,8.69c-1.58,2.46-3.65,4.35-6.23,5.65-2.58,1.31-5.36,1.96-8.33,1.96s-5.76-.65-8.35-1.96ZM908.8,681.82c1.98-1.15,3.55-2.72,4.71-4.71,1.15-1.98,1.73-4.14,1.73-6.48s-.58-4.5-1.73-6.49c-1.16-1.98-2.73-3.55-4.71-4.71-1.98-1.15-4.13-1.73-6.44-1.73s-4.46.58-6.44,1.73c-1.98,1.16-3.55,2.72-4.71,4.71-1.16,1.98-1.73,4.14-1.73,6.49s.58,4.5,1.73,6.48c1.15,1.98,2.72,3.55,4.71,4.71,1.98,1.16,4.13,1.73,6.44,1.73s4.46-.58,6.44-1.73ZM895.78,661.98h7.48c1.68,0,3.11.38,4.28,1.15,1.17.77,1.76,1.94,1.76,3.53,0,1.32-.5,2.38-1.49,3.18s-2.11,1.22-3.38,1.28v-.23c.99.09,1.79.33,2.39.72.6.39,1.06.9,1.37,1.53s.58,1.4.79,2.29l.72,3.51h-4.28l-.63-3.78c-.15-.81-.4-1.43-.74-1.85s-.85-.63-1.51-.63h-2.61v6.26h-4.14v-16.98ZM904.32,668.96c.49-.42.74-1,.74-1.76s-.24-1.32-.72-1.69-1.14-.58-1.98-.61h-2.43v4.64h2.43c.81.03,1.47-.16,1.96-.59Z"/>
  </g>

{/* Lightening */}
  <polyline style={{ stroke: stroke, strokeWidth: "0.5px", fill: rushFill, strokeMiterlimit: 10 }} points="502.48 390.83 610.46 298.05 500.89 298.05 945.51 22.49 807.36 201.12 932.81 201.12 747.02 390.83"/>
  <polyline style={{ stroke: stroke, strokeWidth: "0.5px", fill: rushFill, strokeMiterlimit: 10 }} points="552.06 675.8 449.15 768.44 553.57 768.44 129.83 1043.59 261.49 865.22 141.94 865.22 319 675.8"/>
    </svg>
  );
};

export default LogoRush;
