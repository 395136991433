import React from "react";

const Sidebar = (props) => {
  const { position, flightMode } = props;

  return (
    <div className={`jet__sidebar-container ${position}`}>
      {!flightMode ? (
        <>
          <div className="jet__sidebar-gradient top" />
          <div className="jet__sidebar-gradient bottom" />
        </>
      ) : (
        ""
      )}

      <div className={`jet__sidebar-wrapper ${position}`}>
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line large ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line mid ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
        <div className={`jet__sidebar-line small ${position}`} />
      </div>
    </div>
  );
};

export default Sidebar;
