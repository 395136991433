import { useEffect, useState } from "react";
import sanityClient from "../../client.js";
import JetBackground from "../backgrounds/jet/JetBackground.js";
import SpedoBackground from "../backgrounds/spedo/SpedoBackground.js";

const Press = (props) => {
  const { flightMode, handleFlightMode } = props;

  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "press"][0]`)
      .then((data) => {
        setPageData(data.pressLinks);
      })
      .catch();
  }, []);

  return (
    <section
      key={"press-wrapper"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="press__wrapper"
    >
      <div className="section__title padding">
        <div className="header-blur alt-font">Press:</div>
      </div>

      <div className="press__text-wrapper large-text">
        <h1>Regular features on:{" "}
          {pageData &&
            pageData.map((link, index) => {
              return (
                <>
                  <a
                    href={link.entryLink}
                    key={index}
                    target="_blank"
                    rel="noreferrer"
                    className="press__link"
                  >
                    {link.entryName}
                  </a>
                  {", "}
                </>
              );
            })}
          and many more...
        </h1>
      </div>
      <SpedoBackground {...{ flightMode, handleFlightMode }} />
    </section>
  );
};

export default Press;
