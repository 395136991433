import React from 'react'

const AltMarker = (props) => {

  const { direction } = props;
  return (
    <div className={`jet__alt-marker ${direction}`}>
      <div className={`jet__alt-marker-angle left ${direction}`} />
      <div className={`jet__alt-marker-small ${direction}`} />
      <div className={`jet__alt-marker-large ${direction}`} />
      <div className={`jet__alt-marker-small ${direction}`} />
      <div className={`jet__alt-marker-angle right ${direction}`} />

    </div>
  )
}

export default AltMarker