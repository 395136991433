import { useEffect, useState } from "react";
import sanityClient from "../../client.js";

import ArchiveProject from "./ArchiveProject.js";

const Archive = () => {
  const [pageData, setPageData] = useState(null);

  const [projectData, setProjectData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [latestData, setLatestData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "project"]|order(orderRank){...}`)
      .then((data) => {
        setProjectData(data);
      })
      .catch();
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "selectedProjects"][0]{
        projects[]->{...}
      }`
      )
      .then((data) => {
        setSelectedData(data.projects);
      })
      .catch();
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "latestProject"][0]{
        project->{...}
      }`
      )
      .then((data) => {
        setLatestData(data.project);
      })
      .catch();
  }, []);

  useEffect(() => {
    if (projectData && selectedData && latestData) {
      const removeSelected = projectData.filter(
        (projectItem) =>
          !selectedData.some(
            (selectedItem) => selectedItem._id === projectItem._id
          )
      );
      const removeLatest = removeSelected.filter((obj) => obj !== latestData);
      setPageData(removeLatest);
    }
  }, [projectData, selectedData, latestData]);

  return (
    <section className="archive__wrapper">
      <div className="section__title padding">
        <div className="header-blur alt-font">Archive:</div>
      </div>
      {pageData &&
        pageData.map((project, index) => {
          return <ArchiveProject project={project} key={index} />;
        })}
    </section>
  );
};

export default Archive;
